<template>
  <div class="pagePadding">
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
      </li>
    </ul>
    <div class="btmDiv">
      <!--营业执照信息-->
      <div class="formDiv">
        <BusinessLicenseInfoF v-show="clickIndex == 1"></BusinessLicenseInfoF>
        <ProductionLicenseF v-show="clickIndex == 2"></ProductionLicenseF>
        <RecordCertificateF v-show="clickIndex == 3"> </RecordCertificateF>
      </div>
      <div class="saveDiv clearfix">
        <div class="pageBtn finger btnSure fr">下一步</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'addFactory',
  components: {
    BusinessLicenseInfoF: () => import('./businessLicenseInfoF'),
    ProductionLicenseF: () => import('./productionLicenseF'),
    RecordCertificateF: () => import('./recordCertificateF')
  },
  data() {
    return {
      // 1新增 2修改
      operation: 1,
      navFrist: [
        // {
        //   name: '营业执照信息',
        //   type: 1
        // },
        // {
        //   name: '生产许可证',
        //   type: 2
        // },
        // {
        //   name: '备案凭证',
        //   type: 3
        // }
      ],
      clickIndex: 1
    }
  },
  mounted() {
    this.operation = this.$route.query.operation
    this.init()
  },
  methods: {
    init() {
      // 导航
      if (this.operation === '1') {
        // 新增
        console.log(this.navFrist)
        this.navFrist.push({
          name: '营业执照信息',
          type: 1
        })
      } else {
        // 修改
      }
    },
    clickNav(type) {
      this.clickIndex = type
      console.log(this.clickIndex)
    }
  }
}
</script>

<style scoped lang="less">
.pagePadding {
  padding: 0 !important;
  background: #f0f0f0 !important;
  height: 100px;
  display: flex;
  flex-direction: column;
  .btmDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 20px 25px 30px 25px;
    .formDiv {
      flex: 1;
      overflow-y: auto;
      margin-bottom: 30px;
    }
    .saveDiv {
      height: 36px;
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
</style>
